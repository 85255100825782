import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { JobFeature } from "../components/job-components"
import SuccessStory from "../components/success-story"

const SuccessStoryAmericanUniversityBeirut = ({ data }) => {
  return (
    <SuccessStory
      title="Protecting citizens' privacy while assessing damages after the Beirut Explosion"
      customerName="American University of Beirut"
      customerLocation="Lebanon"
      customerDescription="The American University of Beirut is a private, non-sectarian, and independent university chartered in New York with its campus in Beirut, Lebanon."
      customerIndustries="Research"
      logo=""
      link="https://www.aub.edu.lb/"
      style={{
        background: "url(/images/beirut-highway.jpg) no-repeat",
        backgroundPositionY: "center",
        backgroundSize: "cover",
      }}
    >
      <h2 className="my-3">Challenge</h2>
      <p>
        <strong>
          After the Beirut Explosion in Summer 2020 thousands of buildings and
          public assets were destroyed or badly damaged. Due to the massive size
          of the affected area, damage assessment was tedious.
        </strong>
      </p>
      <p>
        The American University of Beirut (AUB) started to support disaster
        relief operations. Thousands of high-resolution street-level images from
        affected areas were collected with vehicle-mounted 360° cameras, in
        order to better understand the effect of the blast on buildings, and to
        develop a rapid post-disaster damage assement solution using machine
        learning.
      </p>
      <p>
        These images contained personal information of Beirut's citizens, which
        needed to be protected in order to comply with data protection laws. To
        achieve this, the AUB turned to Celantur.
      </p>
      <h2 className="my-3">Solution</h2>
      <Img
        fluid={data.beirutHighway.childImageSharp.fluid}
        className="rounded my-3"
      />
      <p>
        After providing a first image sample set to Celantur, the desired
        anonymization quality was successfully verified. Subsequently the
        complete set consisting of thousands of images was uploaded to
        Celantur's cloud service.
      </p>
      <p>
        The scalable cloud service blurred faces and license plates on the
        images in a matter of few hours. The American University of Beirut
        finally received their anonymized images and could start working on
        their RnD project in a privacy-compliant manner.
      </p>
      <h2 className="my-3">Celantur's contribution</h2>
      <JobFeature>
        Thousands of high-resolution street-level images automatically
        anonymized in a matter of hours
      </JobFeature>
      <JobFeature>
        Enabled automated damage assessment after the Blast in Beirut Harbor in
        2020
      </JobFeature>
      <JobFeature>Privacy of Beirut's citizens protected</JobFeature>
    </SuccessStory>
  )
}

export default SuccessStoryAmericanUniversityBeirut

export const query = graphql`
  query {
    beirutHighway: file(relativePath: { eq: "beirut-highway.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
